import {
  Routes,
  Route,
} from "react-router-dom";

import './css/App.scss';
import './css/Base.scss';

import Profile from "./Pages/Profile";
import Dashboard from "./Pages/Dashboard";
import Entreprises from "./Pages/Entreprises";
import Favoris from "./Pages/Favoris";
import Help from "./Pages/Help";
import Layout from "./Components/Layout";
import Home from "./Pages/Home/Home"
import Download from "./Pages/Download/Download";
import Questions from "./Pages/Questions/Questions";
import Inscriptions from "./Pages/Inscriptions/Inscriptions";
import { useState } from "react";
function App() {
  return (
    <div className="App">
          {/* <Sidenav />
          <main className="container"> */}
          <Layout />
          <Routes>
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            {/* <Route path="/profil" element={<Profile />} /> */}
            {/* <Route path="/entreprises" element={<Entreprises />} /> */}
            {/* <Route path="/favoris" element={<Favoris />} /> */}
            <Route path="/questions" element={<Questions />} />
            <Route path="/download" element={<Download />} />
            <Route path="/aide" element={<Help/>} />
            <Route path="/" element={<Home />} />
            <Route path="/inscriptions" element={<Inscriptions />} />
          </Routes>
          {/* </main> */}
          </div>
  );
}
export default App;

import React from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import AppTitle from '../../Components/Title/AppTitle';

import { NavLink } from "react-router-dom";
import AppButton from "../../Components/Button/AppButton";
import AppText from '../../Components/Text/AppText';
import { ReactComponent as FribourgOpenLogo } from '../../assets/icons/FRIBOURG_OPEN.svg';
import Footer from '../../Components/Footer/Footer';
import RegisteredCompany from '../../Components/RegisteredCompany/RegisteredCompany';
import { ReactComponent as IllustrationFribourgOpen } from '../../assets/icons/Illustration-FribourgOpen.svg'
import { ReactComponent as IllustrationFribourgOpenMobile } from '../../assets/icons/Illustration-FribourgOpen_mobile.svg'
import { useTranslation } from 'react-i18next';
import './inscriptions.scss'

const Inscriptions = () => {
  const { t } = useTranslation();

    return (
        <div className="inscriptions-container">
            <NavBar />
            <RegisteredCompany />
            <section className="section-entreprises">
                            <IllustrationFribourgOpen className='fribourgOpen-illustration' />
                <div className="first">
                    <div className="title">
                        <AppTitle
                            title={t('entrepriseSectionTitle')} fontSize='x2-lg' color='black' isTitle
                        />
                        <AppTitle
                            title={t('entrepriseSectionSubtitle')} fontSize='md' color='blue' isTitle
                        />
                    </div>
                    <div className="content-container">
                        <div className="paragraph">
                            <AppText
                                text={t('entrepriseSectionTextOne')}
                                color='black'
                            />
                            <AppText
                                text={t('entrepriseSectionTextTwo')}
                                color='black'
                            />
                            <AppText
                                text={t('entrepriseSectionTextThree')}
                                color='black'
                            />
                            <AppText
                                text={t('entrepriseSectionTextFour')}
                                color='black'
                            />
                        </div>
                    </div>
                    <div className="formulaire">
                        <AppTitle
                            title={t('entrepriseRegister')} fontSize='md' color='blue' isTitle
                        />
                        <AppText
                            text={t('entrepriseRegisterText')}
                            color='black'
                            className='formulaire-p'
                        />
                        <NavLink
                            to=''
                        >
                            <AppButton 
                            text={t('btnRegister')}
                            type = 'outline'
                            onClick={() => window.open('https://fr.surveymonkey.com/r/FORM_FO?lang=fr', '_blank')}
                            />
                        </NavLink>
                    </div>
                </div>
                <IllustrationFribourgOpenMobile className='fribourgOpen-illustration-mobile' />

            </section>
            <Footer mail="entreprises@fribourgopen.ch" mailto="mailto:entreprises@fribourgopen.ch"/>
        </div>
    );
}

export default Inscriptions; 

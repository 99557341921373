import AppText from '../Text/AppText';
import AppTitle from '../Title/AppTitle'
import AppIcon from '../Icon/AppIcon'
import logoCCIF from '../../assets/icons/ccif_logo_baseline.png';
import logoAll from '../../assets/icons/all.svg';

import googlePlay from '../../assets/icons/googleplay.png';
import appStore from '../../assets/icons/appstore.png';
import './footer.scss';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
const Footer = ({ mail, mailto }) => {
    const { t, i18n } = useTranslation();
    const handleLogoClick = () => {
        if (i18n.language === 'de') {
            window.open('https://www.hikf.ch/', '_blank');
        } else {
            window.open('https://www.ccif.ch', '_blank');
        }
    };
    return (
        <footer className="c-footer">
            <div className='c-footer__links'>

                <div className='c-footer__logo'>
                    <AppText text='Powered by' fontSize='sm' opacity className='c-footer__links__title' />
                    <div className="c-footer__logo-img">
                    <AppIcon icon={logoCCIF} alt='CCIF-logo' className='ccif-img' onClick={handleLogoClick}/>
                    <AppIcon icon={logoAll} alt='CCIF-logo' className='all-img' onClick={() => window.open('https://a-ll.tech/', '_blank')}
/>
                    </div>
                </div>

                <div className='c-footer__contact'>
                    <AppText text={t('contactUs')} fontSize='sm' opacity className='c-footer__links__title' />
                    <div className='c-footer__contact__address'>
                        <AppText text={t('ccif')} />
                        <AppText text={t('adresse')} />
                        <AppText text={t('npa')} />
                    </div>
                    <div className='c-footer__contact__number' >
                        <AppText text='Tel. ' fontSize='md' opacity />
                        <AppText text='+41 26 347 12 20' />
                    </div>
                    <div className='c-footer__contact__email'>
                        <AppText text='Mail.' fontSize='md' opacity />
                        <NavLink
                            onClick={(e) => {
                                window.location.href = mailto;
                                e.preventDefault();
                            }}>
                            <AppText text={mail} />
                        </NavLink>
                    </div>
                </div>
                {/* <div className='c-footer__app'>
                    <AppTitle title={t("footerTitle")} fontSize='lg' />
                    <div className='c-footer__app__button'>
                        <AppIcon icon={googlePlay} alt='googlePlay' styles={{ marginRight: '16px' }} />
                        <AppIcon icon={appStore} alt='appStore' />
                    </div>
                </div> */}

            </div>
        
             

        </footer>
    )
}

export default Footer
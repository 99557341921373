import AppText from '../Text/AppText';
import './appButton.scss';

const AppButton = ({text, type = 'primary', onClick, textSize= 'sm', color, styles, isIconLeft, textOpacity, icon }) => {
  return (
    <button 
      className={`btn btn-${type}
        btn-${isIconLeft ? 'row-reverse' : 'row'}`}
        onClick={onClick}
        style={styles}
    >
      <AppText text={text} fontSize={textSize} opacity={textOpacity} color={color}/>
      {icon && icon}
    </button>
  )
}

export default AppButton